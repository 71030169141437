/** @format */

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
// import Footer from "eco/Footer";
import MiniStatisticsCard from "eco/Cards/StatisticsCards/MiniStatisticsCard";
import ProgressLineChart from "eco/Charts/LineCharts/ProgressLineChart";
import ProfileInfoCard from "eco/Cards/InfoCards/ProfileInfoCard";
import Header from "../../profile/components/Header";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import FormatNumber from "components/formatter/formatNumber";

// Data
import progressLineChartData from "layouts/pages/projects/general/data/progressLineChartData";
import { useParams } from "react-router-dom";
import { useGetFarmerByIdQuery } from "features/slices/farmerSlice";
import { useGetFarmerStatisticByIdQuery } from "features/slices/farmerSlice";
import SoftTypography from "components/SoftTypography";
import breakpoints from "assets/theme/base/breakpoints";

function FarmerDetails() {
    const { id } = useParams();
    const { values } = breakpoints;
    const { data: farmer, isLoading: farmerIsLoading } =
        useGetFarmerByIdQuery(id);
    const { data: farmerStat, isLoading: farmerStatLoading } =
        useGetFarmerStatisticByIdQuery(id);

    return (
        <DashboardLayout>
            <Header
                name={farmer?.full_name || "N/A"}
                isLoading={farmerIsLoading}
                photo={`https://ecoforest.green/assets/agent.webp`}
                role={"Farmer"}
            />
            <SoftBox mt={3}>
                <div style={{ marginTop: "1.3rem" }}>
                    <SoftTypography
                        variant={window.innerWidth < values.sm ? "h3" : "h2"}
                        textTransform='capitalize'
                        fontWeight='bold'
                        gutterBottom
                    >
                        Farmer Statistics
                    </SoftTypography>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={3}>
                                <SoftBox mb={3}>
                                    <LoadingCardSkeleton
                                        isLoading={farmerIsLoading}
                                        height='193px'
                                    >
                                        <MiniStatisticsCard
                                            title={{
                                                fontWeight: "medium",
                                                text: "Total revenue",
                                            }}
                                            count={`${
                                                farmerStat?.total_revenue
                                                    ? farmerStat?.total_revenu
                                                    : 0
                                            }`}
                                            icon={{
                                                color: "dark",
                                                component: "emoji_events",
                                            }}
                                            direction='left'
                                            percentage={{
                                                color: "success",
                                                text: "",
                                            }}
                                        />
                                    </LoadingCardSkeleton>
                                </SoftBox>
                                <LoadingCardSkeleton
                                    isLoading={farmerStatLoading}
                                    height='93px'
                                >
                                    <MiniStatisticsCard
                                        title={{
                                            fontWeight: "medium",
                                            text: "Replaced trees",
                                        }}
                                        c
                                        count={`FRW ${
                                            farmerStat?.replaced_tree || 0
                                        }`}
                                        icon={{
                                            color: "dark",
                                            component: "local_atm",
                                        }}
                                        direction='left'
                                    />
                                </LoadingCardSkeleton>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <SoftBox mb={3}>
                                    <LoadingCardSkeleton
                                        isLoading={farmerStatLoading}
                                        height='93px'
                                    >
                                        <MiniStatisticsCard
                                            title={{
                                                fontWeight: "medium",
                                                text: "Forests",
                                            }}
                                            count={`${farmerStat?.forests}`}
                                            percentage={{
                                                color: "success",
                                                text: "",
                                            }}
                                            icon={{
                                                color: "dark",
                                                component: "emoji_events",
                                            }}
                                            direction='left'
                                            url={`/farmers/${id}/forests`}
                                        />
                                    </LoadingCardSkeleton>
                                </SoftBox>
                                <LoadingCardSkeleton
                                    isLoading={farmerStatLoading}
                                    height='93px'
                                >
                                    <MiniStatisticsCard
                                        title={{
                                            fontWeight: "medium",
                                            text: "Restored land",
                                        }}
                                        count={`${
                                            farmerStat?.restored_land || 0
                                        } Ha`}
                                        percentage={{
                                            color: "success",
                                            text: "",
                                        }}
                                        icon={{
                                            color: "dark",
                                            component: "storefront",
                                        }}
                                        direction='left'
                                    />
                                </LoadingCardSkeleton>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <SoftBox mb={3}>
                                    <LoadingCardSkeleton
                                        isLoading={farmerStatLoading}
                                        height='93px'
                                    >
                                        <MiniStatisticsCard
                                            title={{
                                                fontWeight: "medium",
                                                text: "Total trees",
                                            }}
                                            count={`${
                                                farmerStat?.total_trees || 0
                                            }`}
                                            percentage={{
                                                color: "success",
                                                text: "",
                                            }}
                                            icon={{
                                                color: "dark",
                                                component: "public",
                                            }}
                                            direction='left'
                                            url={`/farmers/${id}/trees/total`}
                                        />
                                    </LoadingCardSkeleton>
                                </SoftBox>
                                <LoadingCardSkeleton
                                    isLoading={farmerStatLoading}
                                    height='93px'
                                >
                                    <MiniStatisticsCard
                                        title={{
                                            fontWeight: "medium",
                                            text: "Loan collected",
                                        }}
                                        count={`${
                                            farmerStat?.loans_collected || 0
                                        }`}
                                        percentage={{
                                            color: "success",
                                            text: "",
                                        }}
                                        icon={{
                                            color: "dark",
                                            component: "storefront",
                                        }}
                                        direction='left'
                                        url={`/farmers/${id}/loans`}
                                    />
                                </LoadingCardSkeleton>
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                                <SoftBox mb={3}>
                                    <LoadingCardSkeleton
                                        isLoading={farmerIsLoading}
                                        height='93px'
                                    >
                                        <MiniStatisticsCard
                                            title={{
                                                fontWeight: "medium",
                                                text: "Survived Trees",
                                            }}
                                            count={`${
                                                farmerStat?.survived_trees || 0
                                            }`}
                                            icon={{
                                                color: "dark",
                                                component: "local_atm",
                                            }}
                                            direction='left'
                                            url='#'
                                        />
                                    </LoadingCardSkeleton>
                                </SoftBox>
                                <LoadingCardSkeleton
                                    isLoading={farmerIsLoading}
                                    height='93px'
                                >
                                    <MiniStatisticsCard
                                        title={{
                                            fontWeight: "medium",
                                            text: "Harvests",
                                        }}
                                        count={`${farmerStat?.harvests || 0}`}
                                        icon={{
                                            color: "dark",
                                            component: "local_atm",
                                        }}
                                        direction='left'
                                        url={`/farmers/${id}/forest/harvests`}
                                    />
                                </LoadingCardSkeleton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={6}>
                                {farmerIsLoading ? (
                                    <LoadingCardSkeleton
                                        isLoading={farmerIsLoading}
                                        height='330px'
                                    />
                                ) : (
                                    <ProfileInfoCard
                                        title='profile information'
                                        description='Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).'
                                        info={{
                                            firstName: `${
                                                (farmer &&
                                                    farmer?.first_name) ||
                                                "N/A"
                                            }`,
                                            lastName: `${
                                                (farmer && farmer?.last_name) ||
                                                "N/A"
                                            }`,
                                            mobile: `${
                                                (farmer && farmer?.phone) ||
                                                "N/A"
                                            }`,
                                            code: `${
                                                (farmer &&
                                                    farmer?.farmer_code) ||
                                                "N/A"
                                            }`,
                                            location: `${
                                                (farmer &&
                                                    farmer?.location_name) ||
                                                "N/A"
                                            }`,
                                            gender: `${
                                                (farmer && farmer?.gender) ||
                                                "N/A"
                                            }`,
                                            education: `${
                                                (farmer && farmer?.education) ||
                                                "N/A"
                                            }`,
                                            maritalStatus: `${
                                                (farmer &&
                                                    farmer?.marital_status) ||
                                                "N/A"
                                            }`,
                                        }}
                                        action={{
                                            route: "",
                                            tooltip: "Edit Profile",
                                        }}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                {farmerIsLoading ? (
                                    <LoadingCardSkeleton
                                        isLoading={farmerIsLoading}
                                        height='330px'
                                    />
                                ) : (
                                    <ProgressLineChart
                                        icon='date_range'
                                        title='Loan payments'
                                        count={FormatNumber(0)}
                                        currency='FRW'
                                        progress={0}
                                        chart={progressLineChartData}
                                        FarmerLoan={[]}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </SoftBox>
        </DashboardLayout>
    );
}

export default FarmerDetails;
