/** @format */

import checkout from "layouts/pages/users/new-user/schemas/form";

const {
    formField: {
        first_name,
        last_name,
        phone,
        country_id,
        email,
        password,
        repeatPassword,
        role,
        location,
        organization,
    },
} = checkout;

const initialValues = {
    [first_name.name]: "",
    [last_name.name]: "",
    [phone.name]: "",
    [country_id.name]: "",
    [email.name]: "",
    [password.name]: "",
    [repeatPassword.name]: "",
    [role.name]: "1",
    [location.name]: "",
    [organization.name]: "",
};

export default initialValues;
