/** @format */

import React from "react";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa";

const StepHeader = ({ step, numberOfStep }) => {
    return (
        <MulterStepContainer>
            <LineThough />
            {Array.from({ length: numberOfStep }, (_, index) => (
                <StepIndicator key={index}>
                    {index < step && <FaCheck size={18} />}
                </StepIndicator>
            ))}
        </MulterStepContainer>
    );
};

export default StepHeader;

const MulterStepContainer = styled.div`
    position: relative;
    display: flex;
    max-width: 500px;
    justify-content: space-between;
    margin: 0 auto;
`;

const StepIndicator = styled.div`
    width: 30px;
    height: 30px;
    border: 1px solid var(--primary-color);
    border-radius: 50%;
    background-color: var(--primary-color);
    color: white;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LineThough = styled.div`
    width: 100%;
    position: absolute;
    top: 15px;
    height: 2px;
    background-color: #161f29;
`;
