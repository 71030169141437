/** @format */

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Soft UI Context Provider
import { SoftUIControllerProvider } from "context";
import { store } from "app/store";
import { Provider } from "react-redux";
import { AuthProvider } from "context/AuthProvider";
import { GlobalStyled } from "./global.styled";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <SoftUIControllerProvider>
                <AuthProvider>
                    <GlobalStyled />
                    <App />
                </AuthProvider>
            </SoftUIControllerProvider>
        </Provider>
        <ToastContainer />
    </BrowserRouter>
);
