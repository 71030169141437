/** @format */

import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import initialValues from "../../../organization/schema/initialValue";
import Card from "@mui/material/Card";
import SoftBox from "../../../../../components/SoftBox";
import SoftButton from "../../../../../components/SoftButton";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
// import ErrorMessageResponse from "../../../../../components/ErrorMessage/ErrorMessageResponse";
import { parentOrganizationFormStep2 } from "../form-data/form";
import { parentOrganizationStep2Schema } from "../form-data/validations";
import AddressField from "../form-data/form-field/AddressField";
import { useDispatch } from "react-redux";
import {
    setOrganizationDetail,
    setStepForm,
    useCreateOrganizationMutation,
} from "../../../../../features/slices/organizationSlice";
import { useSelector } from "react-redux";
import { formatDate } from "@fullcalendar/core";
import ErrorMessageResponse from "components/ErrorMessage/ErrorMessageResponse";

const Addresses = () => {
    const { formField, formId } = parentOrganizationFormStep2;
    const [location, setLocation] = useState(null);
    const [countryName, setCountryName] = useState(null);
    const organizationDetail = useSelector(
        (state) => state.organization.organization_detail
    );
    const dispatch = useDispatch();
    const [createOrganization, { isLoading, isError, error }] =
        useCreateOrganizationMutation();
    const submitForm = async (values, actions) => {
        const formData = new FormData();
        formData.append("website", organizationDetail.website);
        formData.append("name", organizationDetail.name);
        formData.append("logo", organizationDetail.logo);
        formData.append("person_contact", organizationDetail.person_contact);
        formData.append("contact_email", organizationDetail.contact_email);
        formData.append("sub_text", organizationDetail.sub_text);
        formData.append("headline", organizationDetail.headline);
        formData.append("cta", organizationDetail.cta);
        formData.append("currenncy", organizationDetail.currency);
        formData.append("rate", organizationDetail.rate);
        formData.append("currency_symbol", organizationDetail.currency_symbol);
        formData.append("price_per_tree", organizationDetail.price_per_tree);
        formData.append("package_id", organizationDetail.package_id);

        formData.append("country_id", values.country_id);
        formData.append("contact_phone", values.contact_phone);
        formData.append("district", values.district);
        formData.append("location_name", location);
        formData.append("sector", values.sector);
        formData.append("village", values.village);
        formData.append("cell", values.cell);
        formData.append("is_parent", true);

        try {
            const response = await createOrganization(formData).unwrap();
            if (response?.status == "success") {
                toast.success("Parent organization added with successfully");
                dispatch(setOrganizationDetail(response?.data));
                setTimeout(() => {
                    dispatch(setStepForm(2));
                }, 3000);
            }
        } catch (err) {
            actions.setSubmitting(false);
        }
    };
    return (
        <div>
            <Grid container justifyContent='center' sx={{ height: "100%" }}>
                <Grid item xs={12} lg={8}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={parentOrganizationStep2Schema}
                        onSubmit={submitForm}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <Form
                                encType='multipart/form-data'
                                id={formId}
                                autoComplete='off'
                            >
                                <Card sx={{ height: "100%" }}>
                                    <SoftBox p={2}>
                                        <SoftBox>
                                            <AddressField
                                                formData={{
                                                    values,
                                                    touched,
                                                    formField,
                                                    errors,
                                                    setFieldValue,
                                                }}
                                                location={location}
                                                setLocation={setLocation}
                                                setCountryName={setCountryName}
                                            />

                                            <SoftBox
                                                mt={2}
                                                ml={3}
                                                width='100%'
                                                display='flex'
                                                justifyContent='space-between'
                                            >
                                                <SoftButton
                                                    disabled={isSubmitting}
                                                    type='submit'
                                                    variant='gradient'
                                                    color='dark'
                                                >
                                                    {isSubmitting ||
                                                    isLoading ? (
                                                        <CircularProgress
                                                            size={24}
                                                            style={{
                                                                color: "#ffffff",
                                                            }}
                                                        />
                                                    ) : (
                                                        ` save and continue`
                                                    )}
                                                </SoftButton>
                                            </SoftBox>

                                            {!isLoading && isError && (
                                                <ErrorMessageResponse>
                                                    {error?.data?.message}
                                                </ErrorMessageResponse>
                                            )}
                                        </SoftBox>
                                    </SoftBox>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </div>
    );
};

export default Addresses;
