/** @format */

import form from "./form";

const {
    formField: {
        first_name,
        last_name,
        phone,
        email,
        password,
        repeatPassword,
        location,
    },
} = form;

const initialValues = {
    [first_name.name]: "",
    [last_name.name]: "",
    [phone.name]: "",
    [email.name]: "",
    [password.name]: "",
    [repeatPassword.name]: "",
    [location.name]: "",
};

export default initialValues;
