/** @format */

import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTable, useGlobalFilter, useSortBy } from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftPagination from "components/SoftPagination";
import DataTableHeadCell from "eco/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "eco/Tables/DataTable/DataTableBodyCell";
import styled from "styled-components";

function DataTable({
    canSearch,
    showTotalEntries,
    table,
    pagination,
    isSorted,
    noEndBorder,
    theData,
}) {
    const entriesPerPage = 10; // Fixed entries per page
    const [pageIndex, setPageIndex] = useState(0);

    // Total pages based on theData length
    const totalPages = Math.ceil(theData.length / entriesPerPage);

    // Memoizing columns and sliced data
    const columns = useMemo(() => table.columns, [table]);
    const data = useMemo(() => {
        const start = pageIndex * entriesPerPage;
        const end = start + entriesPerPage;
        return theData.slice(start, end);
    }, [theData, pageIndex]);

    // Initializing react-table hooks
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        setGlobalFilter,
        state: { globalFilter },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        useSortBy
    );

    // Search state
    const [search, setSearch] = useState(globalFilter);
    const onSearchChange = (value) => {
        setGlobalFilter(value || undefined);
    };

    // Pagination handlers
    const gotoPage = (index) => {
        if (index >= 0 && index < totalPages) {
            setPageIndex(index);
        }
    };

    const nextPage = () => {
        if (pageIndex < totalPages - 1) {
            setPageIndex((prev) => prev + 1);
        }
    };

    const previousPage = () => {
        if (pageIndex > 0) {
            setPageIndex((prev) => prev - 1);
        }
    };

    // Input for changing pages
    const handleInputPaginationValue = (event) => {
        const value = Number(event.target.value) - 1;
        gotoPage(value);
    };

    // Render the table component
    return (
        <TableContainer sx={{ boxShadow: "none" }}>
            {canSearch && (
                <SoftBox
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    p={3}
                >
                    <SoftBox width='12rem' ml='auto'>
                        <SoftInput
                            placeholder='Search...'
                            value={search}
                            onChange={({ currentTarget }) => {
                                setSearch(currentTarget.value);
                                onSearchChange(currentTarget.value);
                            }}
                        />
                    </SoftBox>
                </SoftBox>
            )}
            <Table {...getTableProps()}>
                <SoftBox component='thead'>
                    {headerGroups.map((headerGroup, key) => (
                        <TableRow
                            key={key}
                            {...headerGroup.getHeaderGroupProps()}
                        >
                            {headerGroup.headers.map((column) => (
                                <DataTableHeadCell key={column.id}>
                                    {column.render("Header")}
                                </DataTableHeadCell>
                            ))}
                        </TableRow>
                    ))}
                </SoftBox>
                <TableBody {...getTableBodyProps()}>
                    {rows.map((row, key) => {
                        prepareRow(row);
                        return (
                            <TableRow key={key} {...row.getRowProps()}>
                                {row.cells.map((cell, key) => (
                                    <DataTableBodyCell
                                        key={key}
                                        noBorder={
                                            noEndBorder &&
                                            rows.length - 1 === key
                                        }
                                        align={cell.column.align || "left"}
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render("Cell")}
                                    </DataTableBodyCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>

            <SoftBox
                display='flex'
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent='space-between'
                alignItems={{ xs: "flex-start", sm: "center" }}
                p={!showTotalEntries && totalPages === 1 ? 0 : 3}
            >
                {showTotalEntries && (
                    <SoftBox mb={{ xs: 3, sm: 0 }}>
                        <SoftTypography
                            variant='button'
                            color='secondary'
                            fontWeight='regular'
                        >
                            Showing {pageIndex * entriesPerPage + 1} to{" "}
                            {Math.min(
                                (pageIndex + 1) * entriesPerPage,
                                theData.length
                            )}{" "}
                            of {theData.length} entries
                        </SoftTypography>
                    </SoftBox>
                )}
                {totalPages > 1 && (
                    <SoftPagination
                        variant={pagination.variant || "gradient"}
                        color={pagination.color || "info"}
                    >
                        {pageIndex > 0 && (
                            <ButtonBox onClick={previousPage}>
                                <Icon sx={{ fontWeight: "bold" }}>
                                    chevron_left
                                </Icon>
                            </ButtonBox>
                        )}
                        {[...Array(totalPages)].map((_, idx) => {
                            // Only show 3 numbers around the current page
                            const start = Math.max(0, pageIndex - 1); // Show one page before the current
                            const end = Math.min(totalPages - 1, pageIndex + 1); // Show one page after the current
                            if (idx < start || idx > end) return null; // Skip pages outside the range

                            return (
                                <SoftPagination
                                    item
                                    key={idx}
                                    value={String(idx + 1)}
                                    onClick={() => gotoPage(idx)}
                                    active={pageIndex === idx}
                                >
                                    {idx + 1}
                                </SoftPagination>
                            );
                        })}
                        <ButtonBox
                            onClick={() => {
                                console.log("go to next page");
                                nextPage();
                            }}
                        >
                            <Icon sx={{ fontWeight: "bold" }}>
                                chevron_right
                            </Icon>
                        </ButtonBox>
                    </SoftPagination>
                )}
            </SoftBox>
        </TableContainer>
    );
}

DataTable.defaultProps = {
    canSearch: false,
    showTotalEntries: true,
    pagination: { variant: "gradient", color: "info" },
    isSorted: true,
    noEndBorder: false,
};

DataTable.propTypes = {
    canSearch: PropTypes.bool,
    showTotalEntries: PropTypes.bool,
    table: PropTypes.objectOf(PropTypes.array).isRequired,
    pagination: PropTypes.shape({
        variant: PropTypes.oneOf(["contained", "gradient"]),
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "light",
        ]),
    }),
    isSorted: PropTypes.bool,
    noEndBorder: PropTypes.bool,
    theData: PropTypes.array.isRequired,
};

const ButtonBox = styled.button`
    background-color: none;
    padding: 12px 12px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
`;

export default DataTable;
