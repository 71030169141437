/** @format */

// @mui material components
import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React eco components
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import Footer from "eco/Footer";
import MiniStatisticsCard from "eco/Cards/StatisticsCards/MiniStatisticsCard";
// import CountryTable from "eco/Tables/CountriesTable";

import ReportsBarChart from "eco/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "eco/Charts/LineCharts/GradientLineChart";
import Globe from "eco/Globe";

// Soft UI Dashboard PRO React base styles
// import typography from "assets/theme/base/typography";
import breakpoints from "assets/theme/base/breakpoints";

// Data
import reportsBarChartData from "layouts/dashboards/default/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboards/default/data/gradientLineChartData";
import {
    useGetGeneralStatisticsQuery,
    // useGetCountryHectaresCoveredQuery,
} from "features/slices/dashboardSlice";
import { useEffect, useState } from "react";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import { useGetFarmerAgentStatQuery } from "features/slices/farmerSlice";
import { useGetFamerMainDashboardQuery } from "features/slices/farmerSlice";
import { useGetLoanCountStatusQuery } from "features/slices/loanSlice";
import { useGetLoanMainDashQuery } from "features/slices/loanSlice";
import { useGetUserByIdQuery } from "features/slices/userSlice";
import { useDispatch } from "react-redux";
import { setUser } from "features/slices/userSlice";

function Default() {
    const { values } = breakpoints;
    // const { size } = typography;
    const { chart, items } = reportsBarChartData;
    const [selected] = useState(null);
    const initialCo2 = Number(0.00000071347032 * 20000).toFixed(2);
    const [co2, setCo2] = useState(initialCo2);
    const dispatch = useDispatch();
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCo2((prevCo2) =>
                (parseFloat(prevCo2) + parseFloat(initialCo2)).toFixed(2)
            );
        }, 1000);

        return () => clearInterval(intervalId);
    }, [initialCo2]);

    const { isLoading: StatisticsIsLoading } = useGetGeneralStatisticsQuery(
        selected?.id,
        {
            refetchOnMountOrArgChange: true,
            skip: false,
        }
    );
    // const { data: CountryHectares, isLoading: CountryHectaresIsLoading } =
    //     useGetCountryHectaresCoveredQuery();
    const { data: LoanCalculations, isLoading: LoanCalculationsIsLoading } =
        useGetLoanCountStatusQuery();
    const { data: LoanGraph, isLoading: LoanGraphIsLoading } =
        useGetLoanMainDashQuery();
    const { data: FarmerAgentGraph, isLoading: FarmerAgentGraphIsLoading } =
        useGetFarmerAgentStatQuery();
    const { data: farmerMainStat, isLoading: isLoadingFarmeMain } =
        useGetFamerMainDashboardQuery();
    const { data: userProfile, isLoading: loadingUser } = useGetUserByIdQuery();
    useEffect(() => {
        if (!loadingUser) {
            dispatch(setUser(userProfile?.data));
        }
    }, [loadingUser]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <Grid container>
                    <Grid item xs={12} lg={7}>
                        <SoftBox mb={3} p={1}>
                            <SoftTypography
                                variant={
                                    window.innerWidth < values.sm ? "h3" : "h2"
                                }
                                textTransform='capitalize'
                                fontWeight='bold'
                            >
                                {` ${
                                    (selected && selected?.name) || "General"
                                } Statistics`}
                            </SoftTypography>
                        </SoftBox>

                        <Grid container>
                            <Grid item xs={12}>
                                <Globe
                                    display={{ xs: "none", md: "block" }}
                                    position='absolute'
                                    top='10%'
                                    right={0}
                                    mt={{ xs: -12, lg: 1 }}
                                    mr={{ xs: 0, lg: 10 }}
                                    canvasStyle={{ marginTop: "3rem" }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={5}>
                                <SoftBox mb={3}>
                                    <LoadingCardSkeleton
                                        isLoading={isLoadingFarmeMain}
                                        height='82px'
                                    >
                                        <MiniStatisticsCard
                                            title={{
                                                text: "Forests",
                                                fontWeight: "bold",
                                            }}
                                            count={
                                                farmerMainStat?.data.forests ||
                                                0
                                            }
                                            percentage={{
                                                color: "success",
                                                text: "+55%",
                                            }}
                                            icon={{
                                                color: "info",
                                                component: "nature",
                                            }}
                                        />
                                    </LoadingCardSkeleton>
                                </SoftBox>

                                <SoftBox mb={3}>
                                    <LoadingCardSkeleton
                                        isLoading={isLoadingFarmeMain}
                                        height='82px'
                                    >
                                        <MiniStatisticsCard
                                            title={{
                                                text: "Hectare restored",
                                                fontWeight: "bold",
                                            }}
                                            count={
                                                farmerMainStat?.data
                                                    ?.restored_land || 0
                                            }
                                            percentage={{
                                                color: "success",
                                                text: "+55%",
                                            }}
                                            icon={{
                                                color: "info",
                                                component: "forests",
                                            }}
                                        />
                                    </LoadingCardSkeleton>
                                </SoftBox>

                                <SoftBox mb={3}>
                                    <LoadingCardSkeleton
                                        isLoading={StatisticsIsLoading}
                                        height='82px'
                                    >
                                        <MiniStatisticsCard
                                            title={{
                                                text: "Trees planted",
                                                fontWeight: "bold",
                                            }}
                                            count={
                                                farmerMainStat?.data
                                                    .total_trees || 0
                                            }
                                            percentage={{
                                                color: "success",
                                                text: "+55%",
                                            }}
                                            icon={{
                                                color: "info",
                                                component: "park",
                                            }}
                                        />
                                    </LoadingCardSkeleton>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <SoftBox mb={3}>
                                    <LoadingCardSkeleton
                                        isLoading={isLoadingFarmeMain}
                                        height='82px'
                                    >
                                        <MiniStatisticsCard
                                            title={{
                                                text: "Farmers",
                                                fontWeight: "bold",
                                            }}
                                            count={
                                                farmerMainStat?.data.farmers ||
                                                0
                                            }
                                            percentage={{
                                                color: "success",
                                                text: "+2%",
                                            }}
                                            icon={{
                                                color: "info",
                                                component: "person",
                                            }}
                                        />
                                    </LoadingCardSkeleton>
                                </SoftBox>

                                <SoftBox mb={3}>
                                    <LoadingCardSkeleton
                                        isLoading={isLoadingFarmeMain}
                                        height='82px'
                                    >
                                        <MiniStatisticsCard
                                            title={{
                                                text: "Agents",
                                                fontWeight: "bold",
                                            }}
                                            count={
                                                farmerMainStat?.data?.agents ||
                                                0
                                            }
                                            percentage={{
                                                color: "success",
                                                text: "+2%",
                                            }}
                                            icon={{
                                                color: "info",
                                                component: "persons",
                                            }}
                                        />
                                    </LoadingCardSkeleton>
                                </SoftBox>

                                <SoftBox mb={3}>
                                    <LoadingCardSkeleton
                                        isLoading={StatisticsIsLoading}
                                        height='82px'
                                    >
                                        <MiniStatisticsCard
                                            title={{
                                                text: "Sequestrated CO2",
                                                fontWeight: "bold",
                                            }}
                                            count={`${farmerMainStat?.data?.co2} kgs`}
                                            percentage={{
                                                color: "success",
                                                text: "+2%",
                                            }}
                                            icon={{
                                                color: "info",
                                                component: "co2",
                                            }}
                                        />
                                    </LoadingCardSkeleton>
                                </SoftBox>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} md={10} lg={7}>
                        <Grid item xs={12} lg={10}>
                            <SoftBox mb={3} position='relative'>
                                <LoadingCardSkeleton
                                    isLoading={CountryHectaresIsLoading}
                                    height='182px'
                                >
                                    <CountryTable
                                        title='Hectares covered by country'
                                        rows={CountryHectares || []}
                                        handleClick={setselected}
                                    />
                                </LoadingCardSkeleton>
                            </SoftBox>
                        </Grid>
                    </Grid> */}
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <LoadingCardSkeleton
                                isLoading={
                                    LoanCalculationsIsLoading ||
                                    LoanGraphIsLoading
                                }
                                height='420px'
                            >
                                <ReportsBarChart
                                    title='Loans'
                                    description={`Total Loans Statistics ${new Date().getFullYear()}`}
                                    chart={chart}
                                    items={items}
                                    LoanCalculations={LoanCalculations?.data}
                                    LoanGraph={LoanGraph?.data}
                                />
                            </LoadingCardSkeleton>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <LoadingCardSkeleton
                                isLoading={FarmerAgentGraphIsLoading}
                                height='420px'
                            >
                                <GradientLineChart
                                    title='Farmers & agents Overview'
                                    height='420px'
                                    description={
                                        <SoftBox
                                            display='flex'
                                            alignItems='center'
                                        >
                                            <SoftTypography
                                                variant='button'
                                                color='text'
                                                fontWeight='medium'
                                            >
                                                {`Total Agents Statistics ${new Date().getFullYear()}`}
                                            </SoftTypography>
                                        </SoftBox>
                                    }
                                    chart={gradientLineChartData}
                                    FarmerAgentGraph={FarmerAgentGraph?.data}
                                />
                            </LoadingCardSkeleton>
                        </Grid>
                    </Grid>
                </Grid>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Default;
