/** @format */

import { useCallback, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import Footer from "eco/Footer";
import DataTable from "eco/Tables/DataTable";

// Data
import dataTableData from "./data/dataTableData";
import { useGetAllAgentsQuery } from "features/slices/agentSlice";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";

import OrgCard from "eco/Cards/OrgCard";
import { useGetAgentStatisticsQuery } from "features/slices/agentSlice";
import SoftTypography from "components/SoftTypography";
import breakpoints from "assets/theme/base/breakpoints";

function AgentsList() {
    const { values } = breakpoints;
    const { data: agents, isLoading: agentIsLoading } = useGetAllAgentsQuery();

    const { data: agentStatistics, isLoading } = useGetAgentStatisticsQuery();

    const paidAmount = agentStatistics?.data?.paid_amount.split(" ");

    return (
        <DashboardLayout>
            <DashboardNavbar />

            <SoftBox pt={3}>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h3" : "h2"}
                    textTransform='capitalize'
                    fontWeight='bold'
                    gutterBottom
                >
                    Agent statistics
                </SoftTypography>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        height={100}
                                        isLoading={isLoading}
                                    >
                                        <OrgCard
                                            count={
                                                agentStatistics?.data?.all || 0
                                            }
                                            suffix=''
                                            title='Agents'
                                            href={`#`}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        height={100}
                                        isLoading={isLoading}
                                    >
                                        <OrgCard
                                            count={
                                                agentStatistics?.data
                                                    ?.activeAgents || 0
                                            }
                                            suffix=''
                                            title='Activated Agents'
                                            href={`#`}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        height={100}
                                        isLoading={isLoading}
                                    >
                                        <OrgCard
                                            count={
                                                agentStatistics?.data
                                                    ?.notActiveAgents || 0
                                            }
                                            suffix=''
                                            title='Not Activated'
                                            href={`#`}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        height={100}
                                        isLoading={isLoading}
                                    >
                                        <OrgCard
                                            count={
                                                agentStatistics?.data
                                                    ?.otpVerifiedAgents || 0
                                            }
                                            suffix=''
                                            title='Verified OTP'
                                            href={`#`}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        height={100}
                                        isLoading={isLoading}
                                    >
                                        <OrgCard
                                            count={
                                                agentStatistics?.data
                                                    ?.otpNotVerifiedAgents || 0
                                            }
                                            suffix=''
                                            title='Not Verified OTP'
                                            href={`#`}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        height={100}
                                        isLoading={isLoading}
                                    >
                                        <OrgCard
                                            count={`${agentStatistics?.data?.paid_amount}`}
                                            suffix=''
                                            title='Paid Amount'
                                            href={`#`}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        height={100}
                                        isLoading={isLoading}
                                    >
                                        <OrgCard
                                            count={
                                                agentStatistics?.data
                                                    ?.panding_payment || 0
                                            }
                                            suffix=''
                                            title='Pending Payment'
                                            href={`#`}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        height={100}
                                        isLoading={isLoading}
                                    >
                                        <OrgCard
                                            count={0}
                                            suffix=''
                                            title='Pending Task'
                                            href={`#`}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>

            <SoftBox my={3}>
                <div style={{ marginTop: "1.3rem" }}>
                    <SoftTypography
                        variant={window.innerWidth < values.sm ? "h3" : "h2"}
                        textTransform='capitalize'
                        fontWeight='bold'
                        gutterBottom
                    >
                        Agent List
                    </SoftTypography>
                </div>

                <LoadingCardSkeleton isLoading={agentIsLoading} height='333px'>
                    <Card>
                        <DataTable
                            table={dataTableData}
                            entriesPerPage={{ defaultValue: 5 }}
                            canSearch={true}
                            theData={agents?.data}
                        />
                    </Card>
                </LoadingCardSkeleton>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AgentsList;
