/** @format */

import React from "react";
import styled from "styled-components";

const ServiceInput = ({
    value,
    label,
    handleChange,
    type,
    isUnit = false,
    name,
    unit,
}) => {
    return (
        <ServiceContainer>
            <Label>{label}</Label>
            <TarifContainer>
                {isUnit && <TextStyled>{unit}</TextStyled>}
                <InputText
                    onChange={handleChange}
                    name={name}
                    value={value}
                    type={type}
                />
            </TarifContainer>
        </ServiceContainer>
    );
};

const TarifContainer = styled.div`
    padding: 6px 14px;
    border-radius: 10px;
    border: 1px solid var(--primary-color);
`;

const TextStyled = styled.span`
    font-size: 15px;
    font-weight: 600;
`;

const ServiceContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.425rem;
`;

const Label = styled.label`
    font-size: 16px;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: 600;
`;

const InputText = styled.input`
    outline: none;
    border: none;
    padding: 6px 14px;
    font-size: 16px;
    font-weight: 600;
    max-width: 80px;
`;

export default ServiceInput;
