/** @format */

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import React, { useState } from "react";
import MuiAlert from "@mui/material/Alert";
import styled from "styled-components";
import marie from "../../../assets/images/placeholder.png";
import Menu from "@mui/material/Menu";
import { Card, Grid, MenuItem, Snackbar, Icon } from "@mui/material";
import { useParams } from "react-router-dom";
import SoftBadge from "components/SoftBadge";
import OrgCard from "eco/Cards/OrgCard";
import { useGetAgentByIdQuery } from "features/slices/agentSlice";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import { useGetAgentStatisticsByIdQuery } from "features/slices/agentSlice";
import { ADMIN_BASE_URL } from "constants";
import { BASE_API_URL } from "constants";
import SoftButton from "components/SoftButton";
import breakpoints from "assets/theme/base/breakpoints";

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant='filled'
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            {...props}
        />
    );
});

const AgentDetail = () => {
    const { id } = useParams();
    const { values } = breakpoints;

    const { data: agent, isLoading } = useGetAgentByIdQuery(id);
    const [successMsg, setSuccessMsg] = useState("");
    const [open, setOpen] = useState(false);

    const { data: agentStat, isLoading: isLoadingStat } =
        useGetAgentStatisticsByIdQuery(id);
    console.log(agent?.data, "Agent");

    // send otp
    const sendOTP = async () => {
        const response = await fetch(
            `${BASE_API_URL}/agents/resend-otp/${agent?.data?.momo_phone}`
        );
        const data = await response.json();
        if (data) {
            setSuccessMsg(data?.message);
            handleClick();
        }
        console.log(data);
    };

    const handleClick = () => {
        setOpen(true);
    };

    // resend PASSWORD
    const resendPassword = async () => {
        const response = await fetch(
            `${BASE_API_URL}/agents/resend-password/${id}`,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
            }
        );
        const data = await response.json();
        if (data) {
            setSuccessMsg(data?.message);
            handleClick();
        }
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const [menu, setMenu] = useState(null);

    const openMenu = (event) => {
        console.log(event.currentTarget);
        setMenu(event.currentTarget);
    };
    const closeMenu = () => setMenu(null);

    const renderStatusMenu = (
        <Menu
            anchorEl={menu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(menu)}
            onClose={closeMenu}
            keepMounted
        >
            <MenuItem
                onClick={() => {
                    sendOTP();
                    closeMenu();
                }}
            >
                SEND OPT REQUEST
            </MenuItem>
            <MenuItem
                onClick={() => {
                    resendPassword();
                    closeMenu();
                }}
            >
                SEND RESET PASSWORD
            </MenuItem>
        </Menu>
    );

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Snackbar
                open={open}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='success'
                    sx={{
                        width: "100%",
                        backgroundColor: "#17c1e8",
                        color: "#FFFFFF",
                    }}
                >
                    {successMsg}
                </Alert>
            </Snackbar>
            <SoftBox>
                <SoftBox>
                    <SoftTypography
                        variant={window.innerWidth < values.sm ? "h3" : "h2"}
                        textTransform='capitalize'
                        fontWeight='bold'
                        gutterBottom
                    >
                        Agent statistics
                    </SoftTypography>
                    <SoftBox>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={3}>
                                        <LoadingCardSkeleton
                                            height={"150px"}
                                            isLoading={isLoadingStat}
                                        >
                                            <OrgCard
                                                count={
                                                    agentStat?.data
                                                        .completed_tasks
                                                }
                                                suffix=''
                                                title='Completed tasks'
                                                href={`#`}
                                            />
                                        </LoadingCardSkeleton>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <LoadingCardSkeleton
                                            height={"150px"}
                                            isLoading={isLoadingStat}
                                        >
                                            <OrgCard
                                                count={
                                                    agentStat?.data
                                                        .pending_tasks
                                                }
                                                suffix=''
                                                title='Pending tasks'
                                                href={`#`}
                                            />
                                        </LoadingCardSkeleton>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <LoadingCardSkeleton
                                            height={"150px"}
                                            isLoading={isLoadingStat}
                                        >
                                            <OrgCard
                                                count={
                                                    agentStat?.data
                                                        .inprogress_tasks
                                                }
                                                suffix=''
                                                title='In progress tasks'
                                                href={`#`}
                                            />
                                        </LoadingCardSkeleton>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <LoadingCardSkeleton
                                            height={"150px"}
                                            isLoading={isLoadingStat}
                                        >
                                            <OrgCard
                                                count={
                                                    agentStat?.data.onhold_tasks
                                                }
                                                suffix=''
                                                title='On Hold tasks'
                                                href={`#`}
                                            />
                                        </LoadingCardSkeleton>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <LoadingCardSkeleton
                                            height={"150px"}
                                            isLoading={isLoadingStat}
                                        >
                                            <OrgCard
                                                count={
                                                    agentStat?.data
                                                        .declined_tasks
                                                }
                                                suffix=''
                                                title='Declined tasks'
                                                href={`#`}
                                            />
                                        </LoadingCardSkeleton>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <LoadingCardSkeleton
                                            height={"150px"}
                                            isLoading={isLoadingStat}
                                        >
                                            <OrgCard
                                                count={
                                                    agentStat?.data
                                                        .panding_payment
                                                }
                                                suffix=''
                                                title='Pending Payment'
                                                href={`#`}
                                            />
                                        </LoadingCardSkeleton>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <LoadingCardSkeleton
                                            height={"150px"}
                                            isLoading={isLoadingStat}
                                        >
                                            <OrgCard
                                                count={`${agentStat?.data.total_amount_paid}`}
                                                suffix=''
                                                title='Total amount Paid'
                                                href={`#`}
                                            />
                                        </LoadingCardSkeleton>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <LoadingCardSkeleton
                                            height={"150px"}
                                            isLoading={isLoadingStat}
                                        >
                                            <OrgCard
                                                count={
                                                    agentStat?.data
                                                        .trees_planted
                                                }
                                                suffix=''
                                                title='Trees Planted'
                                                href={`#`}
                                            />
                                        </LoadingCardSkeleton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </SoftBox>
                </SoftBox>
                <div style={{ marginTop: "1.3rem" }}>
                    <SoftTypography
                        variant={window.innerWidth < values.sm ? "h3" : "h2"}
                        textTransform='capitalize'
                        fontWeight='bold'
                        gutterBottom
                    >
                        Agent Detail
                    </SoftTypography>
                </div>
                <LoadingCardSkeleton isLoading={isLoading}>
                    <Card
                        style={{
                            padding: "2rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: "2rem",
                            marginTop: "1.5rem",
                        }}
                    >
                        <SoftBox
                            display='flex'
                            // alignItems='center'
                            justifyContent='space-between'
                        >
                            <SoftTypography variant='h4' fontWeight='bold'>
                                Agent #{id} details
                            </SoftTypography>

                            <SoftBox display='flex' width={200}>
                                <SoftBox>
                                    <SoftButton
                                        variant={
                                            menu ? "contained" : "outlined"
                                        }
                                        color='secondary'
                                        handleClick={openMenu}
                                    >
                                        Actions....&nbsp;
                                        <Icon>keyboard_arrow_down</Icon>
                                    </SoftButton>
                                    {renderStatusMenu}
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>

                        <Container>
                            <InfoContainer>
                                <SoftBox>
                                    <SoftBox>
                                        <Grid
                                            container
                                            spacing={3}
                                            alignItems='center'
                                        >
                                            <Grid item xs={12} md={12}>
                                                <SoftBox display='flex'>
                                                    <SoftBox mr={4}>
                                                        <ImageContainer>
                                                            <ImageProfile
                                                                src={
                                                                    agent?.data
                                                                        ?.photo !==
                                                                    ""
                                                                        ? `https://roots.ecoforest.green/public/agents/${agent?.data?.photo}`
                                                                        : `https://ecoforest.green/assets/agent.webp`
                                                                }
                                                            />
                                                        </ImageContainer>
                                                    </SoftBox>
                                                    <SoftBox lineHeight={1}>
                                                        <SoftBox
                                                            display='flex'
                                                            alignItems='center'
                                                            mb={0.5}
                                                        >
                                                            <SoftTypography
                                                                variant='body2'
                                                                fontWeight='medium'
                                                            >
                                                                First name:
                                                            </SoftTypography>
                                                            <SoftBox ml={2}>
                                                                <SoftTypography
                                                                    variant='button'
                                                                    fontWeight='regular'
                                                                    color='text'
                                                                >
                                                                    {
                                                                        agent
                                                                            ?.data
                                                                            ?.first_name
                                                                    }
                                                                </SoftTypography>
                                                            </SoftBox>
                                                        </SoftBox>
                                                        <SoftBox
                                                            display='flex'
                                                            alignItems='center'
                                                            mb={0.5}
                                                        >
                                                            <SoftTypography
                                                                variant='body2'
                                                                fontWeight='medium'
                                                            >
                                                                Last Name:
                                                            </SoftTypography>
                                                            <SoftBox ml={2}>
                                                                <SoftTypography
                                                                    variant='button'
                                                                    fontWeight='regular'
                                                                    color='text'
                                                                >
                                                                    {
                                                                        agent
                                                                            ?.data
                                                                            ?.last_name
                                                                    }
                                                                </SoftTypography>
                                                            </SoftBox>
                                                        </SoftBox>
                                                        <SoftBox
                                                            display='flex'
                                                            alignItems='center'
                                                            mb={0.5}
                                                        >
                                                            <SoftTypography
                                                                variant='body2'
                                                                fontWeight='medium'
                                                            >
                                                                Gender:
                                                            </SoftTypography>
                                                            <SoftBox ml={2}>
                                                                <SoftTypography
                                                                    variant='button'
                                                                    fontWeight='regular'
                                                                    color='text'
                                                                >
                                                                    {
                                                                        agent
                                                                            ?.data
                                                                            ?.gender
                                                                    }
                                                                </SoftTypography>
                                                            </SoftBox>
                                                        </SoftBox>
                                                        <SoftBox
                                                            display='flex'
                                                            alignItems='center'
                                                            mb={0.5}
                                                        >
                                                            <SoftBox>
                                                                <SoftTypography
                                                                    variant='body2'
                                                                    fontWeight='medium'
                                                                >
                                                                    Address:
                                                                </SoftTypography>
                                                            </SoftBox>
                                                            <SoftBox ml={2}>
                                                                <SoftTypography
                                                                    variant='button'
                                                                    fontWeight='regular'
                                                                    color='text'
                                                                >
                                                                    {
                                                                        agent
                                                                            ?.data
                                                                            ?.location_name
                                                                    }
                                                                </SoftTypography>
                                                            </SoftBox>
                                                        </SoftBox>
                                                        <SoftBox
                                                            display='flex'
                                                            alignItems='center'
                                                            mb={0.5}
                                                        >
                                                            <SoftBox>
                                                                <SoftTypography
                                                                    variant='body2'
                                                                    fontWeight='medium'
                                                                >
                                                                    Agent code:
                                                                </SoftTypography>
                                                            </SoftBox>
                                                            <SoftBox ml={2}>
                                                                <SoftTypography
                                                                    variant='button'
                                                                    fontWeight='regular'
                                                                    color='text'
                                                                >
                                                                    {
                                                                        agent
                                                                            ?.data
                                                                            ?.agent_code
                                                                    }
                                                                </SoftTypography>
                                                            </SoftBox>
                                                        </SoftBox>
                                                        <SoftBox mb={1}>
                                                            {agent?.data
                                                                ?.is_active ? (
                                                                <SoftBadge
                                                                    variant='gradient'
                                                                    color='success'
                                                                    size='xs'
                                                                    badgeContent='activated'
                                                                    container
                                                                />
                                                            ) : (
                                                                <SoftBadge
                                                                    variant='gradient'
                                                                    color='error'
                                                                    size='xs'
                                                                    badgeContent='Not activated'
                                                                    container
                                                                />
                                                            )}
                                                        </SoftBox>
                                                        <SoftBox>
                                                            {agent?.data
                                                                ?.is_request ? (
                                                                <SoftBadge
                                                                    variant='gradient'
                                                                    color='success'
                                                                    size='xs'
                                                                    badgeContent='verified'
                                                                    container
                                                                />
                                                            ) : (
                                                                <SoftBadge
                                                                    variant='gradient'
                                                                    color='error'
                                                                    size='xs'
                                                                    badgeContent='unverified'
                                                                    container
                                                                />
                                                            )}
                                                        </SoftBox>
                                                    </SoftBox>
                                                </SoftBox>
                                            </Grid>
                                        </Grid>
                                    </SoftBox>
                                </SoftBox>
                                <Grid
                                    // alignItems='center'
                                    item
                                    xs={12}
                                    md={6}
                                    lg={6}
                                >
                                    <SoftBox lineHeight={1}>
                                        <SoftBox
                                            display='flex'
                                            // alignItems='center'
                                            mb={0.5}
                                        >
                                            <SoftTypography
                                                variant='body2'
                                                fontWeight='medium'
                                            >
                                                Whatsapp Phone:
                                            </SoftTypography>
                                            <SoftBox ml={2}>
                                                <SoftTypography
                                                    variant='button'
                                                    fontWeight='regular'
                                                    color='text'
                                                >
                                                    {
                                                        agent?.data
                                                            ?.whatsapp_phone
                                                    }
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                        <SoftBox
                                            display='flex'
                                            alignItems='center'
                                            mb={0.5}
                                        >
                                            <SoftTypography
                                                variant='body2'
                                                fontWeight='medium'
                                            >
                                                Momo Phone:
                                            </SoftTypography>
                                            <SoftBox ml={2}>
                                                <SoftTypography
                                                    variant='button'
                                                    fontWeight='regular'
                                                    color='text'
                                                >
                                                    {agent?.data?.momo_phone}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                        <SoftBox
                                            display='flex'
                                            alignItems='center'
                                            mb={0.5}
                                        >
                                            <SoftTypography
                                                variant='body2'
                                                fontWeight='medium'
                                            >
                                                Joined Date:
                                            </SoftTypography>
                                            <SoftBox ml={2}>
                                                <SoftTypography
                                                    variant='button'
                                                    fontWeight='regular'
                                                    color='text'
                                                >
                                                    {agent?.data?.ym}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                        <SoftBox
                                            display='flex'
                                            alignItems='center'
                                            mb={0.5}
                                        >
                                            <SoftTypography
                                                variant='body2'
                                                fontWeight='medium'
                                            >
                                                Phone:
                                            </SoftTypography>
                                            <SoftBox ml={2}>
                                                <SoftTypography
                                                    variant='button'
                                                    fontWeight='regular'
                                                    color='text'
                                                >
                                                    {agent?.data?.phone}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                        <SoftBox
                                            display='flex'
                                            alignItems='center'
                                            mb={0.5}
                                        >
                                            <SoftBox>
                                                <SoftTypography
                                                    variant='body2'
                                                    fontWeight='medium'
                                                >
                                                    Education:
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftBox ml={2}>
                                                <SoftTypography
                                                    variant='button'
                                                    fontWeight='regular'
                                                    color='text'
                                                >
                                                    {agent?.data?.education}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </SoftBox>
                                </Grid>
                            </InfoContainer>
                        </Container>
                    </Card>
                </LoadingCardSkeleton>
            </SoftBox>
        </DashboardLayout>
    );
};

const Container = styled.div`
    padding: 20px 0;
    display: flex;
    gap: 1rem;
    width: 100%;
`;

const InfoContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
`;
const ImageContainer = styled.div`
    width: 250px;
    height: 250px;
`;
const ImageProfile = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 10px;
`;

export default AgentDetail;
