/** @format */

// import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "features/api/apiSliceV1";

const consumableApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getConsumablePerCountryId: builder.query({
      query: (id) => `consumable/country/${id}`,
      refechOnMount: "always",
      refetchOnReconnect: true,
      transformReconnect: (response, meta, arg) => response.data,
    }),
    updateConsumableService: builder.mutation({
      query: ({ value, id }) => {
        return {
          url: `consumable/${id}`,
          method: "PUT",
          body: value,
        };
      },
    }),
  }),
});

export const {
  useGetConsumablePerCountryIdQuery,
  useUpdateConsumableServiceMutation,
} = consumableApi;
