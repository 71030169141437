/** @format */

import { useState } from "react";

import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import Footer from "eco/Footer";
import DataTable from "eco/Tables/DataTable";

import DefaultCounterCard from "eco/Cards/CounterCards/DefaultCounterCard";
import ReportsDoughnutChart from "eco/Charts/DoughnutCharts/ReportsDoughnutChart";
import reportsDoughnutChartData from "./data/reportsDoughtnutChartData";

// Data
import dataTableData from "./data/dataTableData";
import { useGetAllFarmersQuery } from "features/slices/farmerSlice";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import { useGetFarmerStatisticsQuery } from "features/slices/farmerSlice";
import { useGetFarmerStatisticForestQuery } from "features/slices/farmerSlice";
import { useGetAllFarmersLoanQuery } from "features/slices/farmerSlice";
import { loanOptions } from "constants/data";
import SoftTypography from "components/SoftTypography";
import breakpoints from "assets/theme/base/breakpoints";
// import { useGetAllCountriesQuery } from "features/slices/countrySlice";

function FarmersList() {
    const [filters, setFilters] = useState({
        id: "",
        name: "",
        ongoing: 0,
    });
    const { values } = breakpoints;

    const { data: Farmers, isLoading: FarmersIsLoading } =
        useGetAllFarmersQuery();
    const { data: farmersLoans } = useGetAllFarmersLoanQuery(filters.ongoing);
    const { data: farmerForest, isLoading: farmerLoading } =
        useGetFarmerStatisticForestQuery();
    const { data: farmerStatistics, isLoading: farmerStatLoading } =
        useGetFarmerStatisticsQuery();

    const navigate = useNavigate();
    const [menu, setMenu] = useState(null);
    const openMenu = (event) => setMenu(event.currentTarget);
    const closeMenu = () => setMenu(null);

    const handleLoanTypeFilterChange = (loanType) => {
        setFilters({
            ...filters,
            ...loanType,
        });
    };
    const renderStatusMenu = (
        <Menu
            anchorEl={menu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(menu)}
            onClose={closeMenu}
            keepMounted
        >
            {loanOptions.map((option) => (
                <MenuItem
                    key={option.id}
                    onClick={() => {
                        handleLoanTypeFilterChange({
                            id: option.id,
                            name: option.name,
                            ongoing: option.ongoing,
                        });
                        closeMenu();
                    }}
                >
                    {option.name}
                </MenuItem>
            ))}
        </Menu>
    );

    return (
        <DashboardLayout>
            <DashboardNavbar />

            <SoftBox pt={3}>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h3" : "h2"}
                    textTransform='capitalize'
                    fontWeight='bold'
                    gutterBottom
                >
                    Farmer Statistics
                </SoftTypography>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} xl={7}>
                            <ReportsDoughnutChart
                                title='Farmers information'
                                count={{
                                    number: Farmers?.length,
                                    text: "Farmers",
                                }}
                                chart={reportsDoughnutChartData}
                                tooltip='Farmers statistics'
                                FarmersInformation={farmerStatistics}
                                loading={farmerStatLoading}
                            />
                        </Grid>
                        <Grid item xs={12} xl={5}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <LoadingCardSkeleton
                                        isLoading={farmerLoading}
                                        height='133px'
                                    >
                                        <DefaultCounterCard
                                            count={
                                                farmerForest?.restored_land || 0
                                            }
                                            suffix='ha'
                                            title='Restored Land'
                                            description='Green land'
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <LoadingCardSkeleton
                                        isLoading={farmerLoading}
                                        height='133px'
                                    >
                                        <DefaultCounterCard
                                            count={
                                                farmerForest?.total_trees || 0
                                            }
                                            suffix=''
                                            title='Total trees'
                                            description='consumption'
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <LoadingCardSkeleton
                                        isLoading={farmerLoading}
                                        height='133px'
                                    >
                                        <DefaultCounterCard
                                            count={
                                                farmerForest?.totalJobsCreated ||
                                                0
                                            }
                                            suffix=''
                                            title='Jobs created'
                                            description='all jobs'
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <LoadingCardSkeleton
                                        isLoading={farmerLoading}
                                        height='133px'
                                    >
                                        <DefaultCounterCard
                                            count={
                                                farmerForest?.total_evenue || 0
                                            }
                                            suffix='FRW'
                                            title='Total revenue'
                                            description='Given'
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>

            <SoftBox my={3}>
                <SoftBox
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    mb={2}
                >
                    <div style={{ marginTop: "1.3rem" }}>
                        <SoftTypography
                            variant={
                                window.innerWidth < values.sm ? "h3" : "h2"
                            }
                            textTransform='capitalize'
                            fontWeight='bold'
                            gutterBottom
                        >
                            Farmer List
                        </SoftTypography>
                    </div>
                    <SoftBox display='flex'>
                        <SoftButton
                            variant='gradient'
                            color='info'
                            handleClick={() => navigate("/farmers/add-new")}
                        >
                            New farmer
                        </SoftButton>
                        <SoftBox ml={2}>
                            <SoftButton
                                variant={menu ? "contained" : "outlined"}
                                color='secondary'
                                handleClick={openMenu}
                            >
                                {(filters?.loanType &&
                                    filters?.loanType?.name) || (
                                    <>
                                        filters by loan Type&nbsp;
                                        <Icon>keyboard_arrow_down</Icon>
                                    </>
                                )}
                            </SoftButton>
                            {renderStatusMenu}
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
                <LoadingCardSkeleton
                    isLoading={FarmersIsLoading}
                    height='333px'
                >
                    <Card>
                        <DataTable
                            table={dataTableData}
                            entriesPerPage={{ defaultValue: 15 }}
                            canSearch={true}
                            theData={
                                filters.ongoing === 0 ? Farmers : farmersLoans
                            }
                        />
                    </Card>
                </LoadingCardSkeleton>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default FarmersList;
