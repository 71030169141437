/** @format */

import React, { useState } from "react";
import ReactMapboxGl, { Popup, Marker } from "react-mapbox-gl";
import styled from "styled-components";
import treeIcon from "assets/images/tree-icon.png";
import { Link } from "react-router-dom";

export const mapboxToken = process.env.REACT_APP_MAPBOX;

const Mapbox = ReactMapboxGl({
    accessToken: mapboxToken,
});

const MapboxMap = ({ height = "60vh", center, data }) => {
    const [selected, setSelected] = useState(null);

    return (
        <Mapbox
            style='mapbox://styles/mapbox/streets-v11'
            containerStyle={{
                height,
                width: "100%",
            }}
            center={[center.long, center.lat]}
            zoom={[13]}
        >
            {data?.map((location) => {
                const latitude = parseFloat(location.latitude);
                const longitude = parseFloat(location.longitude);

                if (!latitude || !longitude) return null; // Skip invalid locations

                return (
                    <Marker
                        key={location.id}
                        coordinates={[latitude, longitude]}
                        onClick={() => setSelected()}
                    >
                        <img
                            src={treeIcon}
                            alt='Tree Icon'
                            style={{ width: 30, height: 30, cursor: "pointer" }}
                        />
                    </Marker>
                );
            })}

            {selected && (
                <Popup
                    coordinates={[selected.long, selected.lat]}
                    offset={{
                        bottom: [0, -20],
                    }}
                    onClose={() => setSelected(null)}
                >
                    <PopUpStyle>
                        <h4>Forest name</h4>
                        <p>Mama</p>
                        <h4>Hectars covered</h4>
                        <p>100 ha</p>
                        <h4>Number of trees</h4>
                        <p>100</p>
                        <h4>Dead trees</h4>
                        <p>200</p>
                        <h4>Replaced trees</h4>
                        <p>0</p>
                        <h4>Location</h4>
                        <p>Rwanda, Kigali City</p>
                        <h4>Planted date</h4>
                        <p>2023/12/01</p>
                        <h4>Geolocation</h4>
                        <p>
                            {selected.lat}, {selected.long}
                        </p>
                        <h4>Forest age</h4>
                        <p>2 years</p>
                        <h4>Revenue</h4>
                        <p>100 FRW</p>
                        <h4>View more</h4>
                        <Link
                            to={`/forest/${selected.id}/details/`}
                            style={{
                                color: "blue",
                                display: "block",
                                paddingTop: 5,
                            }}
                        >
                            More details
                        </Link>
                    </PopUpStyle>
                </Popup>
            )}
        </Mapbox>
    );
};

const PopUpStyle = styled.div`
    width: 300px;
    font-size: 14px;
    line-height: 1.5;
    h4 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
    }
    p {
        margin: 5px 0;
    }
`;

export default MapboxMap;
