/** @format */

import React, { useEffect } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import SoftButton from "../../../../../components/SoftButton";
import ServiceInput from "../components/ServiceInput";
import { useGetConsumablePerCountryIdQuery } from "features/slices/consumableSlice";
import { CircularProgress, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useGetUserByIdQuery } from "features/slices/userSlice";
import ErrorMessageResponse from "components/ErrorMessage/ErrorMessageResponse";
import { useCreateOrganizationConsumableMutation } from "features/slices/organizationSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant='filled'
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            {...props}
        />
    );
});

const ConsumableService = () => {
    // const user = useSelector((state)=> state.user.data)
    const [open, setOpen] = React.useState(false);
    const { data: user } = useGetUserByIdQuery();
    const country_id = user?.data?.country_id;
    const navigate = useNavigate();
    const orgs = useSelector((state) => state.organization.organization_detail);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const { data: services, isLoading: isServiceLoading } =
        useGetConsumablePerCountryIdQuery(parseInt(country_id));
    const [createOrganizationService, { isLoading, error, isError }] =
        useCreateOrganizationConsumableMutation();

    const { handleSubmit, values, handleChange, setValues } = useFormik({
        initialValues: {
            monthly_fee: 0,
            price_weather: 0,
            freemium_weather: 0,
            freemium_storage: 0,
            price_storage: 0,
            freemium_ussd_sessions: 0,
            price_ussd_sessions: 0,
            freemium_bulk_sms: 0,
            price_bulk_sms: 0,
            freemium_tree_services: 0,
            price_tree_record: 0,
        },
        onSubmit: async (values) => {
            const updateValued = {
                ...values,
                org_id: parseInt(orgs.id),
                is_default: false,
                storage_unit: "MB",
            };
            try {
                const response = await createOrganizationService(
                    updateValued
                ).unwrap();
                if (response) {
                    handleClick();
                    setTimeout(() => {
                        navigate("/overview/administration");
                    }, 3000);
                }
            } catch (error) {
                console.log(error);
            }
        },
    });

    useEffect(() => {
        if (!isServiceLoading && services?.data) {
            setValues({
                monthly_fee: parseInt(services.data.monthly_fee),
                price_weather: parseFloat(services.data.price_weather),
                freemium_weather: parseInt(services.data.freemium_weather),
                freemium_storage: parseInt(services.data.freemium_storage),
                price_storage: parseFloat(services.data.price_storage),
                freemium_ussd_sessions: parseInt(
                    services.data.freemium_ussd_sessions
                ),
                price_ussd_sessions: parseFloat(
                    services.data.price_ussd_sessions
                ),
                freemium_bulk_sms: parseInt(services.data.freemium_bulk_sms),
                price_bulk_sms: parseFloat(services.data.price_bulk_sms),
                freemium_tree_services: parseInt(
                    services.data.freemium_tree_services
                ),
                price_tree_record: parseFloat(services.data.price_tree_record),
            });
        }
    }, [isServiceLoading, services, setValues]);

    if (isServiceLoading)
        return (
            <LoadingWrapper>
                <CircularProgress
                    size={40}
                    style={{
                        color: "#000",
                    }}
                />
            </LoadingWrapper>
        );

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='success'
                    sx={{
                        width: "100%",
                        backgroundColor: "#17c1e8",
                        color: "#FFFFFF",
                    }}
                >
                    Consumable service created!
                </Alert>
            </Snackbar>
            <Container>
                <Heading>Consumable Services</Heading>
                <FormContainer onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <MainLabel>Server Storage</MainLabel>
                            <FormGroup>
                                <ServiceInput
                                    isUnit={true}
                                    unit={services?.data?.storage_unit}
                                    label={"Freemium Storage"}
                                    name={"freemium_storage"}
                                    value={values.freemium_storage}
                                    handleChange={handleChange}
                                />
                                <ServiceInput
                                    isUnit={true}
                                    unit={orgs?.currency_symbol}
                                    label={`Price per ${services?.data?.storage_unit}`}
                                    name={"price_storage"}
                                    value={values.price_storage}
                                    handleChange={handleChange}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MainLabel>USSD Code Sessions</MainLabel>
                            <FormGroup>
                                <ServiceInput
                                    isUnit={false}
                                    unit={orgs?.currency_symbol}
                                    label={"Freemium Services"}
                                    name={"freemium_ussd_sessions"}
                                    value={values.freemium_ussd_sessions}
                                    handleChange={handleChange}
                                />
                                <ServiceInput
                                    isUnit={true}
                                    unit={orgs?.currency_symbol}
                                    label={"Price Per Session"}
                                    name={"price_ussd_sessions"}
                                    value={values.price_ussd_sessions}
                                    handleChange={handleChange}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <MainLabel>Bulk SMS</MainLabel>
                            <FormGroup>
                                <ServiceInput
                                    isUnit={false}
                                    unit={orgs?.currency_symbol}
                                    label={"Freemium Bulk SMS"}
                                    name={"freemium_bulk_sms"}
                                    value={values.freemium_bulk_sms}
                                    handleChange={handleChange}
                                />
                                <ServiceInput
                                    isUnit={true}
                                    unit={orgs?.currency_symbol}
                                    label={"Price SMS"}
                                    name={"price_bulk_sms"}
                                    value={values.price_bulk_sms}
                                    type={"number"}
                                    handleChange={handleChange}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MainLabel>Tree Update Cost</MainLabel>
                            <FormGroup>
                                <ServiceInput
                                    isUnit={false}
                                    unit={"Rwf"}
                                    label={"Freemium Services"}
                                    name={"freemium_tree_services"}
                                    value={values.freemium_tree_services}
                                    handleChange={handleChange}
                                />
                                <ServiceInput
                                    isUnit={true}
                                    unit={orgs?.currency_symbol}
                                    label={"Price Per record"}
                                    name={"price_tree_record"}
                                    value={values.price_tree_record}
                                    handleChange={handleChange}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <MainLabel>Weather Plan</MainLabel>
                            <FormGroup>
                                <ServiceInput
                                    isUnit={true}
                                    unit={orgs?.currency_symbol}
                                    label={"Freemium Services"}
                                    name={"freemium_weather"}
                                    value={values.freemium_weather}
                                    handleChange={handleChange}
                                />
                                <ServiceInput
                                    isUnit={true}
                                    unit={orgs?.currency_symbol}
                                    label={"14 days Forecast"}
                                    name={"price_weather"}
                                    value={values.price_weather}
                                    handleChange={handleChange}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MainLabel>Montly Fees</MainLabel>
                            <FormGroup>
                                <ServiceInput
                                    isUnit={true}
                                    unit={orgs?.currency_symbol}
                                    label={"28 days"}
                                    name={"monthly_fee"}
                                    value={values.monthly_fee}
                                    handleChange={handleChange}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <SubmitContainer>
                        <SoftButton
                            type='submit'
                            variant='gradient'
                            color='dark'
                        >
                            {isLoading ? (
                                <CircularProgress
                                    size={24}
                                    style={{
                                        color: "#ffffff",
                                    }}
                                />
                            ) : (
                                "Save"
                            )}
                        </SoftButton>
                    </SubmitContainer>
                    {isError && (
                        <ErrorMessageResponse>
                            {error?.data?.message}
                        </ErrorMessageResponse>
                    )}
                </FormContainer>
            </Container>
        </>
    );
};

export default ConsumableService;

const Container = styled.div`
    max-width: 750px;
    margin: 0 auto;
    width: 100%;
    padding: 1em 2em;
    -webkit-box-shadow: -1px 3px 5px 0px rgba(181, 181, 181, 1);
    -moz-box-shadow: -1px 3px 5px 0px rgba(181, 181, 181, 1);
    box-shadow: -1px 3px 5px 0px rgba(181, 181, 181, 1);
    background-color: #fff;
    border-radius: 10px;
`;

const Heading = styled.h2`
    font-size: 20px;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: semibold;
`;

const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
`;

const MainLabel = styled.label`
    font-size: 18px;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: 600;
`;

const SubmitContainer = styled.div`
    display: flex;
    justify-content: end;
`;

const LoadingWrapper = styled.div`
    max-width: 750px;
    margin: 0 auto;
    width: 100%;
    height: 60svh;
    display: flex;
    justify-content: center;
    align-items: center;
`;
