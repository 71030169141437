/** @format */

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import DashboardNavbar from "eco/Navbars/DashboardNavbar";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";

function HeaderDetail({ name, isLoading, role }) {
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");

    useEffect(() => {
        // A function that sets the orientation state of the tabs.
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }

        /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
        window.addEventListener("resize", handleTabsOrientation);

        // Call the handleTabsOrientation function to set the state with the initial value.
        handleTabsOrientation();

        // Remove event listener on cleanup
        return () =>
            window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    return (
        <SoftBox position='relative'>
            <DashboardNavbar absolute light />
            <SoftBox
                display='flex'
                alignItems='center'
                position='relative'
                minHeight='18.75rem'
                borderRadius='xl'
                sx={{
                    backgroundImage: ({
                        functions: { rgba, linearGradient },
                        palette: { gradients },
                    }) =>
                        `${linearGradient(
                            rgba(gradients.info.main, 0.6),
                            rgba(gradients.info.state, 0.6)
                        )}, url(https://media.cntraveler.com/photos/5eb18e42fc043ed5d9779733/16:9/w_2580,c_limit/BlackForest-Germany-GettyImages-147180370.jpg)`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                }}
            />
            <LoadingCardSkeleton
                isLoading={isLoading}
                height='95px'
                skeletonStyles={{
                    marginTop: "-60px",
                    marginLeft: "20px",
                    marginRight: "20px",
                    paddingTop: "12px",
                    paddingBottom: "12px",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                }}
            >
                <Card
                    sx={{
                        backdropFilter: `saturate(200%) blur(30px)`,
                        backgroundColor: ({
                            functions: { rgba },
                            palette: { white },
                        }) => rgba(white.main, 0.8),
                        boxShadow: ({ boxShadows: { navbarBoxShadow } }) =>
                            navbarBoxShadow,
                        position: "relative",
                        mt: -8,
                        mx: "auto",
                        py: 2,
                        px: 2,
                        maxWidth: "300px",
                        height: "150px",
                    }}
                >
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item>
                            <SoftAvatar
                                src={`https://ecoforest.green/assets/agent.webp`}
                                alt='profile-image'
                                variant='rounded'
                                size='xl'
                                shadow='sm'
                            />
                        </Grid>

                        <Grid item>
                            <SoftBox height='100%' mt={0.5} lineHeight={1}>
                                <SoftTypography
                                    variant='h5'
                                    fontWeight='medium'
                                >
                                    {name}
                                </SoftTypography>
                                <SoftTypography
                                    variant='button'
                                    color='text'
                                    fontWeight='medium'
                                >
                                    {role}
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                    </Grid>
                </Card>
            </LoadingCardSkeleton>
        </SoftBox>
    );
}

export default HeaderDetail;
