/** @format */

import React, { useState } from "react";
import DashboardNavbar from "../../../eco/Navbars/DashboardNavbar";
import DashboardLayout from "../../../eco/LayoutContainers/DashboardLayout";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import breakpoints from "../../../assets/theme/base/breakpoints";
import { Button, Card, Grid } from "@mui/material";
import LoadingCardSkeleton from "../../../components/skeleton/LoadingSkeleton";
import OrgCard from "../../../eco/Cards/OrgCard";
import DataTable from "../../../eco/Tables/DataTable";
import { useNavigate } from "react-router-dom";

import { dummyDataPackage } from "../../../constants/data";
import administrationColumn from "./data-table/column.admin";
import { FaPlus } from "react-icons/fa6";
import AdminProfileModal from "./ProfileModal";

function AdministrationPage(props) {
    const { values } = breakpoints;
    const [isLoadingStat] = useState(false);
    const navigate = useNavigate();
    const [modalView, setModalView] = useState(false);
    const [adminId, setAdminId] = useState(null);
    const profileData = {
        name: "GAARA Fund",
        status: "Active",
        parentOrganization: "GAARA Fund",
        organization: "GAARA Fund",
        email: "info@gaara.rw",
        phone: "+250788207071",
        photoUrl: "", // Add photo URL if available
    };

    const handleOpen = (id) => {
        setAdminId(id);
        setModalView(true);
    };
    const handleClose = () => setModalView(false);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox my={3}>
                <SoftBox
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <SoftTypography
                        variant={window.innerWidth < values.sm ? "h3" : "h2"}
                        textTransform='capitalize'
                        fontWeight='bold'
                        gutterBottom
                    >
                        Packages Statistics
                    </SoftTypography>
                    <Button
                        onClick={() => {
                            navigate("/packages/new");
                        }}
                        startIcon={<FaPlus size={20} />}
                        variant='contained'
                        style={{
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                        }}
                        color='primary'
                    >
                        Add packages
                    </Button>
                </SoftBox>
                <SoftBox my={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        height={"150px"}
                                        isLoading={isLoadingStat}
                                    >
                                        <OrgCard
                                            count={0}
                                            suffix=''
                                            title='Basic'
                                            href={`/packages/overview-package/basic`}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        height={"150px"}
                                        isLoading={isLoadingStat}
                                    >
                                        <OrgCard
                                            count={0}
                                            suffix=''
                                            title='Standard'
                                            href={`/packages/overview-package/standard`}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        height={"150px"}
                                        isLoading={isLoadingStat}
                                    >
                                        <OrgCard
                                            count={0}
                                            suffix=''
                                            title='Premium'
                                            href={`/packages/overview-package/premium`}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        height={"150px"}
                                        isLoading={isLoadingStat}
                                    >
                                        <OrgCard
                                            count={0}
                                            suffix=''
                                            title='Customized'
                                            href={`/packages/overview-package/customized`}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
                <SoftBox>
                    <LoadingCardSkeleton
                        isLoading={isLoadingStat}
                        height='333px'
                    >
                        <Card>
                            <DataTable
                                table={administrationColumn(handleOpen)} // Your columns configuration
                                entriesPerPage={{
                                    defaultValue: 5,
                                    entries: [5, 10, 15],
                                }} // Pagination configuration
                                canSearch // Enable search
                                theData={dummyDataPackage} // Your data
                                showTotalEntries // Shows total entries in the table
                                pagination={{
                                    variant: "gradient",
                                    color: "info",
                                }} // Pagination design
                            />
                        </Card>
                    </LoadingCardSkeleton>
                </SoftBox>
            </SoftBox>
            {modalView && (
                <AdminProfileModal
                    open={modalView}
                    onClose={handleClose}
                    profileData={profileData}
                />
            )}
        </DashboardLayout>
    );
}

export default AdministrationPage;
