/** @format */

import React, { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import MuiAlert from "@mui/material/Alert";

// NewUser layout schemas for form and form feilds
import { CircularProgress, Snackbar } from "@mui/material";
import ErrorMessageResponse from "components/ErrorMessage/ErrorMessageResponse";
import { useDispatch, useSelector } from "react-redux";
import UserInfo from "./components/UserInfo";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";
import { useCreateUserMutation } from "features/slices/userSlice";
import { setStepForm } from "features/slices/organizationSlice";

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant='filled'
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            {...props}
        />
    );
});

function OrgUser() {
    const [createUser, { isLoading, isError, error }] = useCreateUserMutation();
    const orgs = useSelector((state) => state.organization.organization_detail);
    const { formId, formField } = form;
    const currentValidation = validations[0];
    const [open, setOpen] = React.useState(false);
    const [showError, setShowError] = useState(false);
    const dispatch = useDispatch();

    const toggleShowError = () => setShowError(!showError);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };
    const submitForm = async (values, actions) => {
        try {
            const newUser = {
                ...values,
                role: "organization",
                country_id: parseInt(orgs.country_id),
                org_id: parseInt(orgs.id),
            };
            console.log(newUser);
            const response = await createUser(newUser).unwrap();
            if (response) {
                handleClick();
                actions.resetForm();
                setTimeout(() => {
                    dispatch(setStepForm(3));
                }, 3000);
            }
        } catch (err) {
            actions.setSubmitting(false);
            toggleShowError(true);
        } finally {
            actions.setSubmitting(false);
        }
    };

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='success'
                    sx={{
                        width: "100%",
                        backgroundColor: "#17c1e8",
                        color: "#FFFFFF",
                    }}
                >
                    Organization User created!
                </Alert>
            </Snackbar>
            <SoftBox py={3} mb={20}>
                <Grid container justifyContent='center' sx={{ height: "100%" }}>
                    <Grid item xs={12} lg={8}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={currentValidation}
                            onSubmit={submitForm}
                        >
                            {({ values, errors, touched, setFieldValue }) => (
                                <Form id={formId} autoComplete='off'>
                                    <Card sx={{ height: "100%" }}>
                                        <SoftBox p={2}>
                                            <SoftBox>
                                                <UserInfo
                                                    formData={{
                                                        values,
                                                        touched,
                                                        formField,
                                                        errors,
                                                        setFieldValue,
                                                    }}
                                                />
                                                <SoftBox
                                                    mt={2}
                                                    width='100%'
                                                    display='flex'
                                                    justifyContent='space-between'
                                                >
                                                    <SoftButton
                                                        disabled={isLoading}
                                                        type='submit'
                                                        variant='gradient'
                                                        color='dark'
                                                    >
                                                        {isLoading ? (
                                                            <CircularProgress
                                                                size={24}
                                                                style={{
                                                                    color: "#ffffff",
                                                                }}
                                                            />
                                                        ) : (
                                                            ` save`
                                                        )}
                                                    </SoftButton>
                                                </SoftBox>

                                                {isError && (
                                                    <ErrorMessageResponse>
                                                        {error.message}
                                                    </ErrorMessageResponse>
                                                )}
                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    );
}

export default OrgUser;
