/** @format */

import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import initialValues from "../../../organization/schema/initialValue";
import Card from "@mui/material/Card";
import SoftBox from "../../../../../components/SoftBox";
import SoftButton from "../../../../../components/SoftButton";
import { CircularProgress } from "@mui/material";
// import ErrorMessageResponse from "../../../../../components/ErrorMessage/ErrorMessageResponse";
import { parentOrgFormStep1 } from "../form-data/form";
import { parentOrganizationFormStep1Schema } from "../form-data/validations";
import DetailFormFiled from "../form-data/form-field/DetailFiled";
import { useDispatch } from "react-redux";
import {
    setOrganizationDetail,
    setStepForm,
} from "../../../../../features/slices/organizationSlice";

const Details = () => {
    const { formId, formField } = parentOrgFormStep1;
    const [logo, setLogo] = useState(null);
    const dispatch = useDispatch();
    const submitForm = async (value) => {
        const organizationDetail = {
            ...value,
            logo: logo,
        };
        dispatch(setOrganizationDetail(organizationDetail));
        dispatch(setStepForm(1));
    };
    return (
        <>
            <Grid container justifyContent='center' sx={{ height: "100%" }}>
                <Grid item xs={12} lg={8}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={parentOrganizationFormStep1Schema}
                        onSubmit={submitForm}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <Form
                                encType='multipart/form-data'
                                id={formId}
                                autoComplete='off'
                            >
                                <Card sx={{ height: "100%" }}>
                                    <SoftBox p={2}>
                                        <SoftBox>
                                            <DetailFormFiled
                                                formData={{
                                                    values,
                                                    touched,
                                                    formField,
                                                    errors,
                                                    setFieldValue,
                                                }}
                                                setLogo={setLogo}
                                            />

                                            <SoftBox
                                                mt={2}
                                                ml={3}
                                                width='100%'
                                                display='flex'
                                                justifyContent='space-between'
                                            >
                                                <SoftButton
                                                    disabled={isSubmitting}
                                                    type='submit'
                                                    variant='gradient'
                                                    color='dark'
                                                >
                                                    {isSubmitting ? (
                                                        <CircularProgress
                                                            size={24}
                                                            style={{
                                                                color: "#ffffff",
                                                            }}
                                                        />
                                                    ) : (
                                                        ` save and continue`
                                                    )}
                                                </SoftButton>
                                            </SoftBox>

                                            {/*{showError &&*/}
                                            {/*    !isLoading &&*/}
                                            {/*    isError && (*/}
                                            {/*        <ErrorMessageResponse>*/}
                                            {/*            {*/}
                                            {/*                error?.data*/}
                                            {/*                    ?.message*/}
                                            {/*            }*/}
                                            {/*        </ErrorMessageResponse>*/}
                                            {/*    )}*/}
                                        </SoftBox>
                                    </SoftBox>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </>
    );
};

export default Details;
