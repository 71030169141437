/** @format */

import React, { useState } from "react";
import ReactMapboxGl, { Marker, Popup } from "react-mapbox-gl";
import { Link } from "react-router-dom";
import styled from "styled-components";
import treeIcon from "assets/images/shapes/tree.jpg";
import SoftBox from "components/SoftBox";

const mapboxToken = process.env.REACT_APP_MAPBOX;

const Mapbox = ReactMapboxGl({
    accessToken: mapboxToken,
});

const style = { paddingBottom: 10, fontSize: 14, fontWeight: 400 };

const TreesMap = ({ height = "80vh", data, center }) => {
    const [selected, setSelected] = useState(null);
    console.log("--------> mp", data);

    return (
        <Mapbox
            style='mapbox://styles/mapbox/streets-v11'
            containerStyle={{
                height: height,
                width: "100%",
            }}
            zoom={[9]}
            center={[center.long, center.lat]}
        >
            {data.map((location, index) => (
                <Marker
                    key={index}
                    coordinates={[location.long, location.lat]}
                    onClick={() =>
                        setSelected({
                            ...location,
                            position: {
                                lat: location.lat,
                                lng: location.long,
                            },
                        })
                    }
                >
                    <img
                        src={treeIcon}
                        alt='Tree Icon'
                        style={{ width: 30, height: 30, cursor: "pointer" }}
                    />
                </Marker>
            ))}

            {selected && (
                <Popup
                    coordinates={[selected.position.lng, selected.position.lat]}
                    offset={{
                        bottom: [0, -20],
                    }}
                    onClose={() => setSelected(null)}
                >
                    <PopUpStyle>
                        <SoftBox>
                            <h4>Tree height</h4>
                            <p style={style}>10</p>
                            <h4>Tree age</h4>
                            <p style={style}>2 years</p>
                            <h4>Agent</h4>
                            <p style={style}>Fabrice</p>
                            <h4>Status</h4>
                            <p style={style}>{0}</p>
                            <h4>Tree location</h4>
                            <p style={style}>Rwanda, Kigali city</p>
                            <h4>Planted date</h4>
                            <p style={style}>2023/12/01</p>
                            <h4>Geolocation</h4>
                            <p style={style}>
                                {selected.position.lat}, {selected.position.lng}
                            </p>
                            <SoftBox>
                                <Link
                                    to={`/farmer/forest/${selected.id}/trees`}
                                >
                                    More details
                                </Link>
                            </SoftBox>
                        </SoftBox>
                    </PopUpStyle>
                </Popup>
            )}
        </Mapbox>
    );
};

const PopUpStyle = styled.div`
    width: 400px;
`;

export default TreesMap;
