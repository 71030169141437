/** @format */

import * as Yup from "yup";
import formElement from "./form";

const {
    formField: {
        first_name,
        last_name,
        phone,
        email,
        password,
        repeatPassword,
        location,
    },
} = formElement;

const validations = [
    Yup.object().shape({
        [first_name.name]: Yup.string().required(first_name.errorMsg),
        [last_name.name]: Yup.string().required(last_name.errorMsg),
        [phone.name]: Yup.string()
            .required(phone.errorMsg)
            .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits"),

        [email.name]: Yup.string()
            .required(email.errorMsg)
            .email(email.invalidMsg),
        [password.name]: Yup.string()
            .required(password.errorMsg)
            .min(6, password.invalidMsg),
        [repeatPassword.name]: Yup.string()
            .required(repeatPassword.errorMsg)
            .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
        [location.name]: Yup.string().required(location.errorMsg),
    }),
];

export default validations;
