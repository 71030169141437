/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSliceV1";

const saveTokenToLocalStorage = (token) => {
    localStorage.setItem("auth-token", token);
};

const removeTokenFromLocalStorage = () => {
    localStorage.removeItem("auth-token");
};

// Define the user API endpoints
export const userApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: () => {
                let apiUrl = "users/my-account";
                return apiUrl;
            },
            refetchOnMount: "always",
            refetchOnReconnect: true,
            refetchOnFocus: "always",
            retry: 3,
            retryOnNetworkFailure: true,
            maxRetries: 3,
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ["users"],
        }),
        getUserById: builder.query({
            query: () => `users/my-account`,
        }),

        loginUser: builder.mutation({
            query: (credentials) => {
                return {
                    url: "users/login",
                    method: "POST",
                    body: credentials,
                };
            },
        }),

        createUser: builder.mutation({
            query: (credentials) => {
                return {
                    url: "users/create-account",
                    method: "POST",
                    body: credentials,
                };
            },
            invalidatesTags: ["users"],
        }),
    }),
});

// Export the individual endpoint hooks
export const {
    useGetUsersQuery,
    useGetUserByIdQuery,
    useLoginUserMutation,
    useCreateUserMutation,
} = userApi;

// Create the user slice
const userSlice = createSlice({
    name: "user",
    initialState: {
        users: [],
        user: null,
        token: null,
    },
    reducers: {
        setToken: (state, { payload }) => {
            state.token = payload;
        },
        setUser: (state, { payload }) => {
            state.user = payload;
        },
        clearUserAndToken: (state) => {
            state.users = [];
            state.token = null;
            state.user = null;
            removeTokenFromLocalStorage();
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            userApi.endpoints.getUsers.matchFulfilled,
            (state, action) => {
                state.users = action.payload;
            }
        );
        builder.addMatcher(
            userApi.endpoints.getUserById.matchFulfilled,
            (state, action) => {
                // Update the state with fetched user data by ID
                return action.payload;
            }
        );
        builder.addMatcher(
            userApi.endpoints.loginUser.matchFulfilled,
            (state, action) => {
                saveTokenToLocalStorage(action?.payload?.token);
                // Update the token in the Redux state
                state.token = action?.payload?.token;
            }
        );
    },
});

// Export the user reducer
export const userReducer = userSlice.reducer;

export const { setToken, clearUserAndToken, setUser } = userSlice.actions;

// Export the user slice
export default userSlice;
